import React from 'react';
import {Grid, Button, Typography as MuiTypography, Paper} from '@material-ui/core';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';

const SpaceGrid = styled(Grid)`
	margin-bottom: 16px;
`;

const Typography = styled(MuiTypography)`
	text-align: center;
`;

const District = props => {
	let {district, senator, email, mailTo, phone, albanyPhone} = props;

	return (
		<div>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				style={{backgroundColor: '#F4F2EF', minHeight: isMobile ? '65vh' : '40vh'}}
			>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					style={{height: '100%'}}
				>
					{/* District */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h4" display="inline">
							District {district}:
						</Typography>
					</SpaceGrid>

					{/* Senator */}
					<SpaceGrid
						xs={isMobile ? 10 : 'auto'}
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Typography variant="h5" display="inline">
							Contact Senator {senator}
						</Typography>
					</SpaceGrid>

					{/* Message */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Grid item xs={isMobile ? 10 : 3}>
							<Grid container direction="row" justify="center" alignItems="center">
								<Typography variant="h6" display="inline">
									Send a pre-drafted email to {email} in support of #SaferNYAct:
								</Typography>
							</Grid>
						</Grid>
					</SpaceGrid>

					{/* Button */}
					<Grid container direction="row" justify="center" alignItems="center">
						<Button color="secondary" variant="contained" size="large" href={mailTo}>
							Send Email
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				style={{backgroundColor: '#fff'}}
			>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					style={{width: isMobile ? '85%' : 'auto'}}
				>
					{/* District */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h5" display="inline">
							{<br />}
							What else can I do?
						</Typography>
					</SpaceGrid>

					{/* Senator */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h6" display="inline">
							Call the District Office: {isMobile && <br />}
							{phone}
						</Typography>
					</SpaceGrid>
					{/* Senator */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h6" display="inline">
							Call the Albany Office: {isMobile && <br />}
							{albanyPhone}
						</Typography>
					</SpaceGrid>
					{/* Senator */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h6" display="inline">
							Text "50A" to 50409{isMobile && <br />} to sign the petition to repeal 50-A
						</Typography>
					</SpaceGrid>

					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Paper variant="outlined">
							<img
								height={isMobile ? 300 : 700}
								src={require(`../flyers/district${district}.png`)}
								alt="flyer"
							/>
						</Paper>
					</SpaceGrid>
					{/* Button */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Button
							color="secondary"
							variant="contained"
							size="large"
							target="blank"
							href={require(`../flyers/district${district}.pdf`)}
						>
							Download Flyer
						</Button>
					</SpaceGrid>

					{/* IMG */}
					<img
						width="100"
						src="https://www.changethenypd.org/sites/default/files/images/safernyactbasic.jpg"
						alt="Safer NY"
					/>

					{/* Senator */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h6" display="inline">
							Read more at{' '}
							<a href="https://www.changethenypd.org/safer-ny" target="blank">
								changethenypd.org/safer-ny
								{<br />}
							</a>
						</Typography>
					</SpaceGrid>

					{/* Senator */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant="h5" display="inline">
							Donate to Communities United for Police Reform
						</Typography>
					</SpaceGrid>

					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Button
							color="secondary"
							variant="contained"
							size="large"
							target="blank"
							href={
								'https://northstarfund.org/give/donate/donate-to-communities-united-for-police-reform/'
							}
						>
							Donate
						</Button>
					</SpaceGrid>
				</Grid>
			</Grid>
		</div>
	);
};

export default District;
