import React from 'react';
import District from '../components/District';

const info = {
	district: 6,
	senator: 'Kevin M. Thomas',
	email: 'thomas@nysenate.gov',
	mailTo: `mailto:thomas@nysenate.gov?subject=Support%20Safer%20NY%20Act%20Bills%20%26%20Repeal%2050-A&body=Dear%20Senator%20Thomas%2C%0D%0A%0D%0AMy%20name%20is%20(your%20name)%20and%20I%20live%20in%20(city%2Ftown).%20I%20am%20writing%20to%20urge%20you%20to%20advocate%20for%20the%20legislature%20to%20reconvene%20and%20pass%20the%20%23SaferNYAct%20bills%2C%20including%20Senator%20Bailey%20and%20Assemblymember%20O'Donnell's%20bill%20to%20repeal%2050-a%20(A2513%2FS3695)%20and%20the%20Police%20STAT%20Act%20(A5472A%2FS1830B).%0D%0A%0D%0AIn%20light%20of%20the%20police%20violence%20occurring%20in%20New%20York%2C%20police%20must%20be%20held%20accountable%20and%2050-a%20must%20be%20repealed%20-%20no%20modifications%20or%20amendments.%20Moreover%2C%20we%20must%20pass%20all%20of%20the%20Safer%20NY%20Act%20bills%20to%20help%20increase%20police%20transparency%20and%20accountability%20to%20all%20New%20Yorkers%20who%20encounter%20police%2C%20but%20especially%20to%20communities%20of%20color%2C%20who%20are%20the%20most%20affected%20by%20police%20violence.%0D%0A%0D%0AFinally%2C%20in%20order%20to%20fully%20support%20this%20mission%2C%20we%20urge%20you%20to%20donate%20or%20invest%20the%20%247%2C250%20you%20have%20received%20from%20police%20and%20corrections%20officers%E2%80%99%20unions%20since%20getting%20elected%20in%202019%20back%20into%20communities%20of%20color.%0D%0A%0D%0ACan%20we%20count%20on%20you%20to%20support%20the%20Safer%20NY%20Act%2C%20ask%20leadership%20to%20bring%20the%20act%20to%20the%20floor%2C%20and%20vote%20yes%20when%20the%20act%20comes%20to%20the%20floor%3F%0D%0A%0D%0ASincerely%2C%0D%0A(your%20name)%0D%0A(today%E2%80%99s%20date)%0D%0A`,
	phone: '(516) 739-1700',
	albanyPhone: '(518) 455-3260'
};

export default function District6() {
	return <District {...info} />;
}
