import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#4C1033'
		},
		secondary: {
			main: '#8F0154'
		},
		typography: {
			useNextVariants: true,
			fontFamily: ['Lexend Deca'].join(','),
			fontSize: 14,
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 600,
			h1: {
				fontSize: '2rem',
				fontFamily: 'Lexend Deca',
				fontWeight: 400,
				lineHeight: 1.2
			},
			h2: {
				fontSize: '1.75rem',
				fontFamily: 'Lexend Deca',
				fontWeight: 400,
				lineHeight: 1.2
			},
			h3: {
				fontSize: '1.5rem',
				fontFamily: 'Lexend Deca',
				fontWeight: 400,
				lineHeight: 1.2
			},
			h4: {
				fontSize: '1.25rem',
				fontFamily: 'Lexend Deca',
				fontWeight: 400,
				lineHeight: 1.2
			},
			h5: {
				fontSize: '1.125rem',
				fontFamily: 'Lexend Deca',
				fontWeight: 400,
				lineHeight: 1.2
			},
			h6: {
				fontSize: '1.0625rem',
				fontFamily: 'Lexend Deca',
				fontWeight: 400,
				lineHeight: 1.2
			},
			caption: {
				opacity: 0.7
			},
			body1: {
				fontSize: 14
			},
			button: {
				textTransform: 'none'
			}
		}
	}
});

ReactDOM.render(
	<BrowserRouter>
		<MuiThemeProvider theme={theme}>
			<ScrollToTop>
				<App />
			</ScrollToTop>
		</MuiThemeProvider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
