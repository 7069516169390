import React from 'react';
import {Grid, Typography as MuiTypography, ButtonGroup, Button} from '@material-ui/core';
import styled from 'styled-components';
import {isMobile} from 'react-device-detect';
import {Link} from 'react-router-dom';
const SpaceGrid = styled(Grid)`
	margin-bottom: 16px;
`;

const Typography = styled(MuiTypography)`
	text-align: center;
`;

const LongIsland = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const Districts = [18, 53, 54, 55, 56];

const Home = props => {
	return (
		<div>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
				style={{backgroundColor: '#fff', minHeight: isMobile ? '100vh' : '90vh'}}
			>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					style={{height: '100%'}}
				>
					{/* District */}
					<SpaceGrid container direction="row" justify="center" alignItems="center">
						<Typography variant={isMobile ? 'h3' : 'h2'} display="inline">
							{isMobile && <br />}
							Pass The #SaferNYAct
						</Typography>
					</SpaceGrid>

					{/* Senator */}
					<SpaceGrid
						xs={isMobile ? 10 : 8}
						container
						direction="row"
						justify="center"
						alignItems="center"
					>
						<Typography variant="h5" display="inline">
							The Safer NY Act is a group of bills in the NY State Legislature that helps
							to increase police transparency and accountability to New Yorkers' most
							common encounters with police.
						</Typography>
						<br />
						<Typography variant="h5" display="inline">
							The #SaferNYAct includes bills to end police secrecy (repealing section
							50-A), report policing data (STAT Act), ensure fair and thorough
							investigations, reduce unnecessary arrests for non-criminal offenses, end
							marijuana prohibition, and invest in communities of color.
						</Typography>
						<br />
						<div style={{padding: 16}}>
							<img
								width="250"
								src="https://www.changethenypd.org/sites/default/files/images/safernyactbasic.jpg"
								alt="Safer NY"
							/>
						</div>
						<br />
						<SpaceGrid container direction="row" justify="center" alignItems="center">
							<Typography variant="h6" display="inline">
								Read more at{' '}
								<a href="https://www.changethenypd.org/safer-ny" target="blank">
									changethenypd.org/safer-ny
								</a>
							</Typography>
						</SpaceGrid>
						<br />
						<SpaceGrid container direction="row" justify="center" alignItems="center">
							<Typography variant="h5" display="inline">
								Contact your Senator:
							</Typography>
						</SpaceGrid>
						<Grid container direction="row" justify="center" alignItems="center">
							{' '}
							{isMobile ? (
								<div>
									{LongIsland.concat(Districts).map((item, index) => (
										<div style={{paddingTop: 4}}>
											<Link
												key={index}
												to={`/district${item}`}
												style={{textDecoration: 'none'}}
											>
												<Button color="secondary" variant="contained" fullWidth>
													District {item}
												</Button>
											</Link>
										</div>
									))}
								</div>
							) : (
								<div>
									<ButtonGroup
										variant="contained"
										color="secondary"
										aria-label="contained primary button group"
									>
										{LongIsland.map((item, index) => (
											<Button>
												<Link
													key={index}
													to={`/district${item}`}
													style={{textDecoration: 'none', color: '#fff'}}
												>
													District {item}
												</Link>
											</Button>
										))}
									</ButtonGroup>
									<br />
									<br />
									<ButtonGroup
										variant="contained"
										color="secondary"
										aria-label="contained primary button group"
									>
										{Districts.map((item, index) => (
											<Button>
												<Link
													key={index}
													to={`/district${item}`}
													style={{textDecoration: 'none', color: '#fff'}}
												>
													District {item}
												</Link>
											</Button>
										))}
									</ButtonGroup>
								</div>
							)}
						</Grid>

						<Grid container direction="row" justify="center" alignItems="center">
							{' '}
							<a href="https://www.nysenate.gov/find-my-senator" target="blank">
								<br />Find your District <br />
							</a>
						</Grid>

						{/* Senator */}
						<SpaceGrid container direction="row" justify="center" alignItems="center">
							<Typography variant="h6" display="inline">
								<br />
								Donate to Communities United for Police Reform
							</Typography>
						</SpaceGrid>

						<SpaceGrid container direction="row" justify="center" alignItems="center">
							<Button
								color="secondary"
								variant="contained"
								size="large"
								target="blank"
								href={
									'https://northstarfund.org/give/donate/donate-to-communities-united-for-police-reform/'
								}
							>
								Donate
							</Button>
						</SpaceGrid>

						{isMobile && <br />}
					</SpaceGrid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Home;
