import React from 'react';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import Home from './Home';
import District1 from './districts/district1';
import District2 from './districts/district2';
import District3 from './districts/district3';
import District4 from './districts/district4';
import District5 from './districts/district5';
import District6 from './districts/district6';
import District7 from './districts/district7';
import District8 from './districts/district8';
import District9 from './districts/district9';
import District18 from './districts/district18';
import District53 from './districts/district53';
import District54 from './districts/district54';
import District55 from './districts/district55';
import District56 from './districts/district56';
import Header from './layout/Header';

function App() {
	return (
		<div>
			<Header />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/district1" component={District1} />
				<Route exact path="/district2" component={District2} />
				<Route exact path="/district3" component={District3} />
				<Route exact path="/district4" component={District4} />
				<Route exact path="/district5" component={District5} />
				<Route exact path="/district6" component={District6} />
				<Route exact path="/district7" component={District7} />
				<Route exact path="/district8" component={District8} />
				<Route exact path="/district9" component={District9} />
				<Route exact path="/district18" component={District18} />
				<Route exact path="/district53" component={District53} />
				<Route exact path="/district54" component={District54} />
				<Route exact path="/district55" component={District55} />
				<Route exact path="/district56" component={District56} />
			</Switch>
		</div>
	);
}

export default App;
