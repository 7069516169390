import React from 'react';
import styled from 'styled-components';
import {Grid, AppBar as MuiAppBar, Toolbar} from '@material-ui/core';
import {Link} from 'react-router-dom';

const AppBar = styled(MuiAppBar)`
background-color: #220120;
	color: #fff;
  box-shadow: #ccc;
 
`;

const Header = ({onDrawerToggle}) => (
	<React.Fragment>
		<AppBar position="sticky" elevation={0}>
			<Toolbar>
				<Grid container alignItems="center">
					<Grid item>
						<Link to={'/'}>
							<svg
								width="148px"
								height="19px"
								viewBox="0 0 148 19"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>Safer NY Act</title>
								<desc>Created with Sketch.</desc>
								<g
									id="Page-1"
									stroke="none"
									stroke-width="1"
									fill="none"
									fill-rule="evenodd"
									font-family="LexendDeca-Regular, Lexend Deca"
									font-size="24"
									font-weight="normal"
								>
									<text id="Safer-NY-Act" fill="#FFFFFF">
										<tspan x="-1" y="18">
											Safer NY Act
										</tspan>
									</text>
								</g>
							</svg>
						</Link>
					</Grid>

					<Grid item xs />
				</Grid>
			</Toolbar>
		</AppBar>
	</React.Fragment>
);

export default Header;
